<template>

<app-content>

	<app-content-head title="Overview" subtitle="See an overview of revenue, fees, and payouts across all users.">

	</app-content-head>

	<app-content-body>

		<div class="summary-wrapper">

			<app-content-summary title="Transactions & Fees" icon="registration" :grow="true">

				<app-content-summary-item label="Payments" :grow="true" :value="$platform.count.billing.platform.sales_total | formatMoney" />
				<app-content-summary-item label="Refunds" :grow="true" :value="$platform.count.billing.platform.refunds_total | formatMoney('-')" />
				<app-content-summary-item label="Card Fees" :grow="true" :value="$platform.count.billing.platform.stripe_total | formatMoney('-')" />
				<app-content-summary-item label="Client Revenue" :grow="true" :value="$platform.count.billing.platform.client_total | formatMoney('-')" />
				<app-content-summary-item label="Net Revenue" :grow="true" :value="$platform.count.billing.platform.net_total | formatMoney('=')" />

			</app-content-summary>

			<app-content-summary title="Payouts" icon="payouts">
 
				<app-content-summary-item label="Paid" :value="$platform.count.billing.platform.payouts_paid_total | formatMoney" />
				<app-content-summary-item label="Queued" :value="$platform.count.billing.platform.payouts_queued_total | formatMoney" />
				<app-content-summary-item label="Pending" :value="$platform.count.billing.platform.payouts_pending_total | formatMoney" />

			</app-content-summary>

		</div>

	</app-content-body>

</app-content>

</template>

<script>

export default {

}

</script>

<style scoped>

.summary-wrapper {
	display: flex;
}

</style>